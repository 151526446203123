import { __awaiter, __generator } from "tslib";
import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import Input from "@/presentation/components/input/Input.vue";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import routePath from "@/commons/RoutePath";
import { useStore } from "vuex";
import PopupCroppie from "@/presentation/components/popup-croppie/PopupCroppie.vue";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
import { getCurrentAccountId } from "@/commons/UserStatusUtis";
export default defineComponent({
    name: "CreateTeam",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        PopupCroppie: PopupCroppie,
        Input: Input
    },
    computed: {
        placeholderText: function () {
            return "\u4F8B\u2460\uFF1A\u30C1\u30FC\u30E0\u306F\u30E1\u30F3\u30D0\u30FC\u306E\u30B9\u30AD\u30EB\u30D0\u30E9\u30F3\u30B9\u304C\u826F\u304F\u3001\u30D3\u30B8\u30CD\u30B9\u30FB\u30C7\u30B8\u30BF\u30EB\u306E\u53CC\u65B9\u306E\u89B3\u70B9\u3067\u306E\u691C\u8A0E\u304C\u53EF\u80FD\u3067\u3059\u3002\n\u4F8B\u2461\uFF1AAI\u30E2\u30C7\u30EB\u69CB\u7BC9\u306B\u5F37\u307F\u306E\u3042\u308B\u30E1\u30F3\u30D0\u30FC\u304C\u63C3\u3063\u3066\u304A\u308A\u3001\u30B3\u30F3\u30DA\u3067\u306FXX\u306E\u3088\u3046\u306A\u53D7\u8CDE\u6B74\u3082\u3042\u308A\u307E\u3059\u3002";
        }
    },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var _a = useForm({
            initialValues: {
                name: "",
                name_kana: "",
                description: "",
                image: "",
            }
        }), setValues = _a.setValues, setFieldValue = _a.setFieldValue, values = _a.values, isSubmitting = _a.isSubmitting, errors = _a.errors, validate = _a.validate, setErrors = _a.setErrors;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: "/user/dashboard",
                    name: "ダッシュボード"
                },
                {
                    route: "",
                    name: "プロフィール"
                },
                {
                    route: "",
                    name: "新規チーム作成"
                }
            ],
            errorValidateMessage: [],
            serverError: null,
            showCropImage: false
        });
        onMounted(function () {
            state.listBreadcrumb[1].route = routePath.USER_PROFILE + "/" + getCurrentAccountId() + "/info";
        });
        // validate
        var name = useField("name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(50, agh.sprintf(state.aLang.validation_max_length, 50))).value;
        var name_kana = useField("name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(50, agh.sprintf(state.aLang.validation_max_length, 50))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var description = useField("description", yup
            .string()
            .required(state.aLang.validation_required)
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var image = useField("image", yup
            .string()).value;
        function goBack() {
            return router.push(routePath.TEAMS);
        }
        function checkErrorValidate(field, error) {
            if (!error[field]) {
                return false;
            }
            if (state.errorValidateMessage[field]) {
                state.errorValidateMessage[field] = [];
            }
            return true;
        }
        function goConfirm() {
            return __awaiter(this, void 0, void 0, function () {
                var valid, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, validate()];
                        case 1:
                            valid = _a.sent();
                            if (!valid) {
                                return [2 /*return*/];
                            }
                            data = Object.assign({}, values);
                            store.commit("setCreateTeamData", data);
                            router.push(routePath.CONFIRM_TEAM);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function close() {
            state.showCropImage = false;
        }
        function onChangeImage(e) {
            setValues({
                image: e
            });
        }
        function initData() {
            var createTeamData = store.state.createTeamData;
            if (createTeamData.name &&
                createTeamData.name_kana &&
                createTeamData.description) {
                setValues(createTeamData);
            }
        }
        onMounted(function () {
            initData();
        });
        router.beforeEach(function (to, from, next) {
            if ((from.name == "CreateTeam" && to.name != "ConfirmTeam") || (from.name == "ConfirmTeam" && to.name != "CreateTeam")) {
                store.commit("setCreateTeamData", {});
                next();
            }
            next();
        });
        return {
            state: state,
            values: values,
            errors: errors,
            isSubmitting: isSubmitting,
            goBack: goBack,
            checkErrorValidate: checkErrorValidate,
            goConfirm: goConfirm,
            close: close,
            onChangeImage: onChangeImage
        };
    }
});
