<template>
  <div class="create_team">
    <NavBar />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="container">
      <div class="main-area">
        <div class="create_team__form">
          <h3 class="create_team__form_title">新規チームの作成</h3>
          <p>
            新規に作成したいチーム内容を入力してください。チームメンバーを招待する場合、チーム登録後に招待したい人宛に招待メールが届きます。招待メールから参加を承認すると参加となります。
          </p>
          <form class="horizontal">
            <div class="form-ctrl">
              <label  class="text">チーム名<span>（必須）</span></label>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <Input
                        maxlength="50"
                        type="text"
                        placeholder="チーム名を入力してください"
                        v-model="values.name"/>
                    <ErrorMessage v-if="errors.name" :message="errors.name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label  class="text"
                >（チーム名）カナ<span>（必須）</span></label
              >
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <Input
                      maxlength="50"
                      type="text"
                      placeholder="チーム名のカナを入力してください"
                      v-model="values.name_kana"
                    />
                    <ErrorMessage
                      v-if="errors.name_kana"
                      :message="errors.name_kana"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-form-ctrl">
              <div class="form-ctrl">
                <label  class="text height-unset"
                  >チーム画像 200 x 200px
                  <span>※検索結果に表示されます</span></label
                >
                <div class="form-group">
                  <div class="image_team">
                    <a @click="state.showCropImage = true">
                      <span> 写真を追加 <i class="fas fa-pen"></i></span>
                      <img v-if="values.image" :src="values.image" alt="" />
                    </a>
                  </div>
                  <ErrorMessage v-if="errors.image" :message="errors.image" />
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label class="text">
                PRポイント
                <span>（必須）</span>
              </label>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <Textarea
                      maxlength="10000"
                      name="description"
                      id="description"
                      v-model="values.description"
                      placeholder="例①：チームはメンバーのスキルバランスが良く、ビジネス・デジタルの双方の観点での検討が可能です。
例②：AIモデル構築に強みのあるメンバーが揃っており、コンペではXXのような受賞歴もあります。"
                    ></Textarea>
                    <ErrorMessage
                      v-if="errors.description"
                      :message="errors.description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_group mb-10">
              <button
                class="btn-md btn-light-gray shadow"
                type="button"
                @click="goBack"
              >
                キャンセル
              </button>
              <button
                type="button"
                class="btn-md btn-blue shadow"
                @click="goConfirm"
              >
                登録内容の確認
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <PopupCroppie
      v-if="state.showCropImage"
      type="team"
      :id="null"
      :image="values.image"
      @close="close"
      @changeImage="onChangeImage"
      :viewport="{ width: 200, height: 200 }"
      :boundary="{ width: 300, height: 300 }"
    />
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/team/create-team/CreateTeam.ts" scoped>
</script>

<style lang="scss" src="@/presentation/views/user/team/create-team/CreateTeam.scss" scoped>
</style>
